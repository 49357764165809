import React from "react";
import PropTypes from "prop-types";
import styles from "./ConfirmationModal.module.css";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  header = "Confirm Action",
  message = "Are you sure you want to proceed?",
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  confirmButtonColor = "bg-red-600",
  confirmButtonHoverColor = "hover:bg-red-700"
}) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={`${styles.deleteModal} !bg-modal`}>
        <h2 className="text-lg font-semibold text-shade">{header}</h2>
        <p className="mt-2 text-shade">{message}</p>
        <div className={`${styles.modalButtons} gap-3 pt-3`}>
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
          >
            {cancelButtonText}
          </button>
          <button
            type="button"
            onClick={onConfirm}
            className={`px-4 py-2 text-white rounded-md ${confirmButtonColor} ${confirmButtonHoverColor}`}
          >
            {confirmButtonText}
          </button>
        </div>
      </div>
    </div>
  );
};
ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  header: PropTypes.string,
  message: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonColor: PropTypes.string,
  confirmButtonHoverColor: PropTypes.string
};
ConfirmationModal.defaultProps = {
  header: "Confirm Action",
  message: "Are you sure you want to proceed?",
  confirmButtonText: "Confirm",
  cancelButtonText: "Cancel",
  confirmButtonColor: "bg-red-600",
  confirmButtonHoverColor: "hover:bg-red-700"
};
export default ConfirmationModal;
