import React from "react";
import MainNavbar from "../Components/Dashboard/MainNavbar/MainNavbar";

const PrivacyPolicy = () => {
  return (
    <div className="bg-white min-h-screen text-black">
      <MainNavbar />
      <div className="container mx-auto px-8 py-8">
        <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Introduction</h2>
          <p>
            At FlexAgents, we respect and protect your privacy. This Privacy
            Policy outlines how we collect, use, and safeguard your personal
            information when you use our platform to create and manage
            AI-powered chatbots. By using our services, you agree to the
            collection and use of information in accordance with this policy.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">
            Information We Collect
          </h2>
          <p>
            We collect different types of information to provide and improve our
            services. This information may include:
          </p>
          <ul className="list-disc pl-6">
            <li>
              <strong>Personal Information:</strong> When you sign up or use our
              services, we may collect personal data such as your name, email
              address, and payment information.
            </li>
            <li>
              <strong>Usage Data:</strong> We collect data about your
              interactions with our platform, such as the features you use, your
              activity logs, and metadata related to the AI-generated chatbots
              you create.
            </li>
            <li>
              <strong>Device Information:</strong> Information about the device
              you use to access our platform, including browser type, operating
              system, and IP address.
            </li>
            <li>
              <strong>Cookies and Tracking Technologies:</strong> We use cookies
              and similar tracking technologies to monitor activity on our site
              and improve user experience. You can control cookies through your
              browser settings.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">
            How We Use Your Information
          </h2>
          <p>
            We use the information we collect for various purposes, including:
          </p>
          <ul className="list-disc pl-6">
            <li>
              To provide, maintain, and improve our platform and services.
            </li>
            <li>
              To personalize your experience by offering features that suit your
              preferences.
            </li>
            <li>
              To communicate with you, including sending important updates and
              responding to your inquiries.
            </li>
            <li>
              To analyze usage trends and improve the performance of our
              platform.
            </li>
            <li>
              To comply with legal obligations and enforce our terms of service.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">
            Data Sharing and Disclosure
          </h2>
          <p>
            We do not sell, rent, or trade your personal information to third
            parties. However, we may share your data in the following
            situations:
          </p>
          <ul className="list-disc pl-6">
            <li>
              <strong>Service Providers:</strong> We may share your information
              with trusted third-party service providers who help us operate our
              platform (e.g., payment processors, hosting services).
            </li>
            <li>
              <strong>Legal Compliance:</strong> We may disclose your
              information if required by law or if we believe such action is
              necessary to comply with a legal obligation, protect our rights,
              or prevent fraud.
            </li>
            <li>
              <strong>Business Transfers:</strong> In the event of a merger,
              acquisition, or sale of assets, your personal data may be
              transferred to the new entity.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Data Security</h2>
          <p>
            We take reasonable measures to protect the security of your personal
            information, including using encryption protocols and secure server
            infrastructure. However, no method of transmission over the internet
            or electronic storage is 100% secure, and we cannot guarantee
            absolute security.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Your Data Rights</h2>
          <p>
            Depending on your location, you may have certain rights regarding
            your personal data. These rights may include:
          </p>
          <ul className="list-disc pl-6">
            <li>
              <strong>Access:</strong> You have the right to request access to
              the personal information we hold about you.
            </li>
            <li>
              <strong>Correction:</strong> You can request corrections to any
              inaccurate or incomplete data.
            </li>
            <li>
              <strong>Deletion:</strong> You may request that we delete your
              personal data, subject to certain exceptions (e.g., legal
              retention requirements).
            </li>
            <li>
              <strong>Opt-out:</strong> You can opt-out of marketing
              communications by following the unsubscribe link in emails or
              contacting us directly.
            </li>
          </ul>
          <p>
            To exercise any of these rights, please contact us using the contact
            details provided below.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">
            Children&apos;s Privacy
          </h2>
          <p>
            Our platform is not intended for use by individuals under the age of
            18. We do not knowingly collect personal information from children.
            If we learn that we have collected personal information from a child
            under 18, we will take steps to delete such information as soon as
            possible.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">
            Changes to This Privacy Policy
          </h2>
          <p>
            We may update our Privacy Policy from time to time. Any changes will
            be posted on this page with an updated &quot;Effective Date.&quot;
            review this policy periodically for any updates.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at{" "}
            <a href="mailto:support@aidevlab.com" className="text-blue-500">
              support@aidevlab.com
            </a>
            .
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
