import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../Store/slices/UserSlice";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";

const ProfileMenu = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { user } = useSelector((state) => {
    return state.user;
  });

  const handleConfirmLogout = async () => {
    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.getSubscription();
    if (subscription) {
      await subscription.unsubscribe();
    }

    dispatch(logout());
    nav("/");
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="dropdown dropdown-end">
        <div className="tooltip tooltip-bottom" data-tip="Profile">
          <button
            type="button"
            tabIndex={0}
            className="h-8 w-8 rounded-full cursor-pointer"
            style={{ background: user?.profileGradient }}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          />
        </div>
        {isDropdownOpen && (
          <ul
            role="menu"
            className="dropdown-content menu p-2 shadow rounded-box w-52 mt-2 z-[9999] bg-background dark:border dark:border-white text-black"
          >
            {user?.role === "SUPER_ADMIN" && (
              <li>
                <button
                  type="button"
                  onClick={() => nav("/admin")}
                  className="hover:bg-gray-500 hover:text-white dark:hover:text-shade dark:hover:bg-[#32507e] text-shade"
                >
                  Admin Settings
                </button>
              </li>
            )}
            <li>
              <button
                type="button"
                onClick={() => nav("/account-settings")}
                className="hover:bg-gray-500 hover:text-white dark:hover:text-shade dark:hover:bg-[#32507e] text-shade"
              >
                Profile Settings
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => nav("/api-keys")}
                className="hover:bg-gray-500 hover:text-white dark:hover:text-shade dark:hover:bg-[#32507e] text-shade"
              >
                Api Keys
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => {
                  setIsModalOpen(true);
                  setIsDropdownOpen(false);
                }}
                className="hover:bg-gray-500 hover:text-white dark:hover:text-shade dark:hover:bg-[#32507e] text-shade"
              >
                Logout
              </button>
            </li>
          </ul>
        )}
      </div>

      {/* Logout Confirmation Modal */}
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmLogout}
        header="Confirm Logout"
        message="Are you sure you want to logout?"
        confirmButtonText="Logout"
        cancelButtonText="Cancel"
        confirmButtonColor="bg-red-600"
        confirmButtonHoverColor="hover:bg-red-700"
      />
    </>
  );
};

export default ProfileMenu;
