import React from "react";
// TODO: We will replace it later
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import logoPartLeft from "../../../Assets/Images/logoPartLeft.png";
import logoPartRight from "../../../Assets/Images/logoPartRight.png";
import ThemeToggle from "../../ThemeToggle";
import NotificationMenu from "../../Shared/Navbar/NotificationMenu";
import ProfileMenu from "./ProfileMenu";

// TODO: It will be changed when we merge other branch's code
const MainNavbar = () => {
  const { user } = useSelector((state) => {
    return state.user;
  });
  const nav = useNavigate();

  // Handle navigation on key press
  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      nav("/dashboard");
    }
  };

  return (
    <nav className="flex justify-between items-center py-5 px-8 bg-white dark:bg-background shadow-sm">
      <div
        role="button"
        tabIndex="0"
        onClick={() => nav("/dashboard")}
        onKeyDown={handleKeyPress} // Add onKeyDown for keyboard accessibility
        className="flex items-center space-x-3 cursor-pointer"
      >
        <img
          src={logoPartLeft}
          alt="Logo"
          className="h-8 dark:invert dark:brightness-100"
        />
        <img
          src={logoPartRight}
          alt="Logo"
          className="mr-10 dark:invert dark:brightness-100"
        />
      </div>
      <div className="flex items-center space-x-3">
        <div className="tooltip tooltip-bottom" data-tip="Toggle Theme">
          <ThemeToggle />
        </div>
        <div className="tooltip tooltip-bottom" data-tip="Notifications">
          <NotificationMenu />
        </div>
        <div className="flex items-center gap-2">
          {/* Display the user's name at the top of the dropdown */}
          {user?.name && (
            <span className="text-md font-semibold text-primary text-shade">
              {user.name}
            </span>
          )}
          <ProfileMenu />
        </div>
      </div>
    </nav>
  );
};

export default MainNavbar;
