import React, { useEffect, useRef, useState } from "react";
import { FaBell } from "react-icons/fa";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { IoIosClose } from "react-icons/io";
import useSocket from "../../../hooks/useSocket";
import useClickAway from "../../../hooks/useClickAway";
import {
  getAllNotifications,
  clearAllNotifications
} from "../../../Services/notificationServices"; // Import the API

const NotificationMenu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [notificationsArr, setNotificationsArr] = useState([]);
  const { notifications } = useSocket();
  const ref = useRef(null);

  useClickAway(ref, () => {
    setShowMenu(false); // Close the component when a click outside occurs
  });

  // Fetch notifications from the API when the component loads
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await getAllNotifications(); // Call the API
        if (response?.data) {
          setNotificationsArr(response.data); // Update state with fetched notifications
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications(); // Call the function on first load
  }, []); // Empty dependency array ensures this runs only on mount

  const clearAll = async () => {
    try {
      await clearAllNotifications(); // Call the API to clear notifications
      setNotificationsArr([]); // Clear notifications in the state
    } catch (error) {
      console.error("Error clearing notifications:", error);
    }
  };

  const notificationTray = () => {
    if (notificationsArr.length === 0) {
      return (
        <div className="h-[100%] flex justify-center items-center">
          <h2 className="text-center text-gray-300">No new notifications</h2>
        </div>
      );
    }

    return notificationsArr.map((notification) => (
      <div
        key={notification.id}
        className={`flex items-start p-4 rounded-md border mb-2 mr-2 ${
          notification.success ? "border-[#398b39]" : "border-red-700"
        }`}
      >
        <div
          className={`${
            notification.success ? "text-[#398b39]" : "text-red-700"
          }`}
        >
          <p className="text-sm font-medium ">{notification.message}</p>
          {!notification.success && (
            <p className="text-sm">{notification?.description}</p>
          )}
        </div>
      </div>
    ));
  };

  useEffect(() => {
    if (notifications) {
      setNotificationsArr((prevNotifications) => [
        ...prevNotifications,
        notifications
      ]);
    }
  }, [notifications]);

  return (
    <div className="relative" ref={ref}>
      <button
        type="button"
        onClick={() => setShowMenu(!showMenu)}
        className="btn btn-ghost btn-circle relative"
      >
        <FaBell className="w-5 h-5 dark:text-white" />

        {notificationsArr.length > 0 && (
          <div className="w-[12px] h-[12px] text-[8px] rounded-full absolute top-[5px] right-[8px] text-white bg-red-500 flex justify-center items-center">
            <span>{notificationsArr.length}</span>
          </div>
        )}
      </button>
      {showMenu && (
        <div className="absolute z-10 right-0  w-96 bg-modal shadow-2xl rounded-lg light:border py-3 px-5">
          <div className=" flex justify-between items-center">
            <span className="font-bold text-md text-primary text-shade ">
              Notifications
            </span>
            <div className="flex items-center gap-1">
              <MdOutlineDeleteOutline
                className="text-[20px] text-secondary cursor-pointer dark:text-shade"
                onClick={clearAll}
              />

              <IoIosClose
                onClick={() => setShowMenu(false)}
                className=" text-[2rem] text-secondary cursor-pointer dark:text-shade"
              />
            </div>
          </div>
          <div className="h-[400px] overflow-y-auto py-3">
            {notificationTray()}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationMenu;
