import { flowAxiosInstance } from "./axiosInterceptors";

const getAllNotifications = async () => {
  try {
    const response = await flowAxiosInstance.get("notifications/all"); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during fetching notifications:", error);
    throw error;
  }
};

const clearAllNotifications = async () => {
  try {
    const response = await flowAxiosInstance.delete("notifications/clear"); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during fetching notifications:", error);
    throw error;
  }
};

const getVapidKeysApi = async () => {
  try {
    const response = await flowAxiosInstance.get(
      "/notifications/get-vapid-keys?applicationName=flexagents"
    );
    return response;
  } catch (error) {
    console.error("Error during fetching keys:", error);
    throw error;
  }
};

const subscribeUserApi = async (payload) => {
  try {
    const response = await flowAxiosInstance.post(
      "/notifications/subscribe",
      payload
    );
    return response;
  } catch (error) {
    console.error("Error during subscribing user:", error);
    throw error;
  }
};

export {
  getAllNotifications,
  clearAllNotifications,
  getVapidKeysApi,
  subscribeUserApi
};
