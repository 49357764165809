import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_SOCKET_URL);

const useSocket = () => {
  const [notifications, setNotifications] = useState();
  const [progressReport, setProgressReport] = useState();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    // Handle socket connection
    socket.on("connect", () => {
      console.log("WebSocket connected with ID:", socket.id);
    });

    // Handle socket disconnection
    socket.on("disconnect", (reason) => {
      console.log("WebSocket disconnected:", reason);
    });

    // Handle connection errors
    socket.on("connect_error", (error) => {
      console.error("WebSocket connection error:", error);
    });

    // Add the listeners
    socket.on("notification", (data) => {
      // console.log("Notification:", data);
      setNotifications(data);
    });

    socket.on("progressReport", (data) => {
      // console.log("Progress Report:", data);
      setProgressReport(data);
    });

    // Register the user after listeners are set up
    if (user?.id) {
      socket.emit("register", { userId: user.id });
    }

    // Clean up the listeners and socket events on unmount
    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("connect_error");
      socket.off("notification");
      socket.off("progressReport");
    };
  }, []); // Empty dependency array ensures listeners are registered on mount

  // Return the socket, notifications, and progress report states
  return { socket, notifications, progressReport, setProgressReport };
};

export default useSocket;
