import React from "react";
import MainNavbar from "../Components/Dashboard/MainNavbar/MainNavbar";

const TermsOfUse = () => {
  return (
    <div className="bg-white min-h-screen text-black">
      <MainNavbar />
      <div className="container mx-auto px-8 py-8">
        <h1 className="text-3xl font-bold mb-4">Terms of Use</h1>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Introduction</h2>
          <p>
            Welcome to FlexAgents, a platform that enables you to easily create
            AI-powered chatbots using machine learning and drag-and-drop
            features. By accessing or using our services, you agree to comply
            with the following terms and conditions. Please read them carefully.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Terms of Service</h2>
          <p>
            1. By using FlexAgents, you agree to not use the platform for any
            illegal activities, including, but not limited to, creating or
            distributing harmful, offensive, or inappropriate content.
          </p>
          <p>
            2. FlexAgents reserves the right to suspend or terminate user
            accounts involved in any misuse of the platform.
          </p>
          <p>
            3. You are responsible for any content you upload, generate, or
            distribute through our platform.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">User Responsibilities</h2>
          <p>
            Users must ensure that all data entered into the platform, including
            any uploaded files or generated chatbot content, complies with local
            laws, regulations, and ethical guidelines. FlexAgents is not
            responsible for any misuse of the platform&apos;s capabilities.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Restrictions</h2>
          <p>You are prohibited from:</p>
          <ul className="list-disc pl-6">
            <li>
              Using the platform to engage in any illegal activities, including
              hacking or data mining.
            </li>
            <li>Distributing malicious software or viruses.</li>
            <li>
              Violating intellectual property rights related to the platform or
              third-party content.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Privacy Policy</h2>
          <p>
            We value your privacy. Please review our{" "}
            <a href="/privacy-policy" className="text-blue-500">
              Privacy Policy
            </a>{" "}
            to understand how we collect, use, and protect your personal data.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
          <p>
            If you have any questions or concerns about these Terms of Use,
            please contact us at{" "}
            <a href="mailto:support@aidevlab.com" className="text-blue-500">
              support@aidevlab.com
            </a>
            .
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfUse;
