// src/components/ThemeToggle.js
import React from "react";
import { FaMoon, FaSun } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { updateThemeThunk } from "../Store/slices/ThemeSlice";

const ThemeToggle = () => {
  const dispatch = useDispatch();

  const { mode: theme } = useSelector((state) => {
    return state.theme;
  });

  const toggleTheme = async () => {
    dispatch(updateThemeThunk(theme === "light" ? "dark" : "light"))
      .unwrap()
      .then(() => {
        document.body.classList.remove(theme);
        document.body.classList.add(theme === "light" ? "dark" : "light");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="flex items-center">
      <div
        onClick={toggleTheme}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            toggleTheme(e);
          }
        }}
        role="button"
        tabIndex="0"
        className={`relative w-14 h-8 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 ease-in-out ${
          theme === "dark" ? "bg-gray-200" : "bg-gray-200"
        }`}
      >
        <div
          className={`absolute left-1 w-6 h-6 flex items-center justify-center rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${
            theme === "dark" ? " bg-gray-600" : "translate-x-6 bg-yellow-500"
          }`}
        >
          {theme === "dark" ? (
            <FaMoon className="text-white w-4 h-4" />
          ) : (
            <FaSun className="text-white w-4 h-4" />
          )}
        </div>
      </div>
    </div>
  );
};

export default ThemeToggle;
